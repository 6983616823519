import { createStore } from 'vuex'

// const API_URL = "http://localhost/booking-api/public/";

let API_URL;
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost/booking-api/public/";
} else {
  API_URL = process.env.BASE_URL + "api/"
}

export default createStore({
  state: {
    loading: false,
    storeList: [],
    allCityList: [],
    availableHours: [],

    appointmentStore: "",
    appointmentDate: "",
    appointmentHour: "",
    firstName: "",
    familyName: "",
    email: "",
    phone: "",
    eventDate: "",
    marketingConfirm: false,
    termConfirm: false,
    appointmentNumber: "",
    eventPlace: "",
    cityLive: "",
    categoriesInterest: [],
    existingBookingDate: "",
    existingBookingNumber: "",
    existingBookingStore: "",
    notificationErrors: []
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },

    getAllCityList(state) {
      return state.allCityList;
    },

    getStores(state) {
      return state.storeList;
    },

    getAvailableHours(state) {
      let currentTimes = state.availableHours;
      if (!currentTimes.length) return [];

      currentTimes.sort((a, b) => {
        a = parseInt(a.replace(/:/g, ''));
        b = parseInt(b.replace(/:/g, ''));

        return a - b;
      });

      return currentTimes;
    },

    getAppointmentStore(state) {
      return state.appointmentStore
    },
    getAppointmentDate(state) {
      return state.appointmentDate
    },
    getAppointmentHour(state) {
      return state.appointmentHour;
    },

    getAppointmentTime(state) {
      const date = new Date(state.appointmentDate);
      const time = state.appointmentHour;

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year} - ${time}`;
    },

    getFirstName(state) {
      return state.firstName
    },
    getFamilyName(state) {
      return state.familyName
    },
    getEmail(state) {
      return state.email
    },
    getPhone(state) {
      return state.phone
    },
    getEventDate(state) {
      return state.eventDate
    },
    getMarketingConfirm(state) {
      return state.marketingConfirm
    },
    getTermConfirm(state) {
      return state.termConfirm
    },
    getAppointmentNumber(state) {
      return state.appointmentNumber
    },

    getEventPlace(state) {
      return state.eventPlace
    },

    getCityLive(state) {
      return state.cityLive
    },

    getCategoriesInterest(state) {
      return state.categoriesInterest
    },

    getExitingBooking(state) {
      if (!state.existingBookingDate) return null;

      const date = new Date(state.existingBookingDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const hour = date.getHours();
      const minute = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();

      const dateView = `${day}/${month}/${year} - ${hour}:${minute}`;

      return {
        date: dateView,
        number: state.existingBookingNumber,
        storeCode: state.existingBookingStore
      }
    },

    getNotificationErrors(state) {
      return state.notificationErrors;
    },
  },
  mutations: {
    setLoading(state, status) {
      state.loading = status;
    },

    setAllCityList(state, data) {
      state.allCityList = data;
    },

    setStores(state, data) {
      state.storeList = data;
    },

    setAvailableHours(state, data) {
      state.availableHours = data;
    },

    setAppointmentStore(state, data) {
      state.appointmentStore = data;
    },
    setAppointmentDate(state, data) {
      state.appointmentDate = data;
    },
    setAppointmentHour(state, data) {
      state.appointmentHour = data;
    },
    setFirstName(state, data) {
      state.firstName = data;
    },
    setFamilyName(state, data) {
      state.familyName = data;
    },
    setEmail(state, data) {
      state.email = data;
    },
    setPhone(state, data) {
      state.phone = data;
    },
    setEventDate(state, data) {
      state.eventDate = data;
    },
    setMarketingConfirm(state, data) {
      state.marketingConfirm = data;
    },
    setTermConfirm(state, data) {
      state.termConfirm = data;
    },
    setAppointmentNumber(state, data) {
      state.appointmentNumber = data;
    },

    setEventPlace(state, data) {
      state.eventPlace = data;
    },

    setCityLive(state, data) {
      state.cityLive = data;
    },

    setCategoriesInterest(state, data) {
      state.categoriesInterest = data;
    },

    setExitingBooking(state, payload) {
      const { date, number, storeCode } = payload;

      state.existingBookingDate = date;
      state.existingBookingNumber = number;
      state.existingBookingStore = storeCode;
    },

    deleteNotificationError(state, errorIndex) {
      state.notificationErrors.splice(errorIndex, 1);
    },

    clearNotificationError(state) {
      state.notificationErrors = [];
    }
  },
  actions: {
    setNotificationError({ state, commit }, errorMessage, autoHide = false) {
      state.notificationErrors.push(errorMessage);

      if (autoHide) {
        setTimeout(() => {
          const index = state.notificationErrors.findIndex(msg => msg == errorMessage);
          if (index > -1) {
            commit("deleteNotificationError", index)
          }
        }, 3000);
      }
    },

    async listAllCity(context) {
      context.commit("setLoading", true);

      try {
        const response = await fetch(`${API_URL}AllCityList`);
        let items = await response.json();

        context.commit("setAllCityList", items);
      } catch (error) {
        context.dispatch("setNotificationError", error.message);
      }

      context.commit("setLoading", false);

    },

    async listStores(context) {
      context.commit("setLoading", true);

      try {
        const response = await fetch(`${API_URL}StoreList`);
        let stores = await response.json();

        context.commit("setStores", stores);
      } catch (error) {
        context.dispatch("setNotificationError", error.message);
      }

      context.commit("setLoading", false);

    },

    async listHours(context, data) {
      const { store, date } = data;
      context.commit("clearNotificationError");
      context.commit("setLoading", true);
      context.commit("setAvailableHours", []);

      let dateVal = new Date(date);
      // let bookingDate = new Date(dateVal.setTime(dateVal.getTime() + 3 * 60 * 60 * 1000));
      let bookingDate = new Date(dateVal.setTime(dateVal.getTime()));

      const day = bookingDate.getDate();
      const month = bookingDate.getMonth() + 1;
      const year = bookingDate.getFullYear();
      const dateFormat = `${year}-${month}-${day}`;

      try {
        const response = await fetch(`${API_URL}PossibleTimes?StoreCode=${store}&BookingDate=${dateFormat}`);
        let items = await response.json();

        if (response.status !== 200) throw new Error(items.message);

        items = items.map(item => item.ValidHour.slice(0, 5));

        context.commit("setAvailableHours", items);
      } catch (error) {
        if(error.message === "No ValidHour found for the provided params.") {
          error.message = "Mağazamızın seçtiğiniz tarihteki tüm randevuları dolmuştur.";
        }
        context.dispatch("setNotificationError", error.message);
      }

      context.commit("setLoading", false);
    },

    async existingBooking(context, data) {
      const { phone, email } = data;
      context.commit("setLoading", true);

      return new Promise((resolve, reject) => {
        fetch(`${API_URL}ExistingBooking?Phone=${phone}&Email=${email}`)
          .then(res => res.json())
          .then(booking => {
            booking = booking[0];

            if (booking.Result === "NotExists") {
              resolve(false);
            } else {
              const bookingData = {
                date: booking.StartDate,
                number: booking.BookingNumber,
                storeCode: booking.StoreCode
              };

              context.commit("setExitingBooking", bookingData)
              resolve(bookingData);
            }

          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      });
    },

    async createStoreAppointment({ commit }, payload) {
      commit("setLoading", true);

      return new Promise((resolve, reject) => {
        fetch(`${API_URL}CreateStoreBooking`, {
          method: "POST",
          cache: "no-cache",
          body: JSON.stringify(payload), // body data type must match "Content-Type" header
        })
          .then(response => response.json())
          .then(res => {

            if (res.Result == "true") {
              commit("setAppointmentNumber", res.BookingNumber);
              resolve();
            } else {
              reject(res)
            }
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            commit("setLoading", false)
          })
      });
    },

    async createOnlineAppointment({ commit }, payload) {
      commit("setLoading", true);

      return new Promise((resolve, reject) => {
        fetch(`${API_URL}CreateBooking`, {
          method: "POST",
          cache: "no-cache",
          body: JSON.stringify(payload), // body data type must match "Content-Type" header
        })
          .then(response => response.json())
          .then(res => {

            if (res.Result == "true") {
              commit("setAppointmentNumber", res.BookingNumber);
              resolve();
            } else {
              reject(res)
            }
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            commit("setLoading", false)
          })
      });
    },
  },
  modules: {
  }
})
