<script setup>
</script>

<template>
    <footer id="footer">
        <p>Copyright &copy; {{ new Date().getFullYear() }} Oleg Cassini - All rights reserved.</p>
    </footer>
</template>

<style lang="scss">
#footer {
    display: flex;
    width: 100%;
    height: var(--footer-height);
    line-height: var(--footer-height);
    align-items: center;
    justify-content: center;

    p {
        font-size: 0.725rem;
        text-align: center;
    }
}
</style>