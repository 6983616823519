<script setup>
import { ref } from 'vue';

const publicPath = ref(process.env.BASE_URL);
</script>

<template>
    <header id="header">
        <a href="https://olegcassini.com.tr">
            <img :src="`${publicPath}image/logo.webp`" width="240" height="32" alt="Oleg Cassini" />
        </a>
    </header>
</template>

<style lang="scss">
#header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--header-height);
}
</style>