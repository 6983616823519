<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const items = computed(() => {
    return store.getters.getNotificationErrors;
});

const removeError = (index) => {
    store.commit("deleteNotificationError", index)
}
</script>

<template>
    <div class="notification-errors">
        <TransitionGroup name="list">
            <div class="error-item" v-for="(item, index) in items" :key="index">
                {{ item }}

                <span class="close" @click="removeError(index)">X</span>
            </div>
        </TransitionGroup>
    </div>
</template>

<style lang="scss" scoped>
.notification-errors {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 300px;
    gap: 1rem;
    top: 1rem;
    left: calc(50% - 150px);

    .error-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
        color: #fff;
        background-color: #f51860;
        border-radius: 6px;
        box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);

        .close {
            font-size: 0.875rem;
            cursor: pointer;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>