import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/Home/Index',
    redirect: '/'
  },
  {
    path: '/BrideForm',
    redirect: '/online'
  },
  {
    path: '/',
    alias: '/store',
    name: 'store',
    component: () => import(/* webpackChunkName: "store" */ '../views/store/IndexView.vue'),
    meta: { title: "Gelinlik Randevusu Al" },
  },
  {
    path: '/online',
    name: 'online',
    component: () => import(/* webpackChunkName: "online" */ '../views/online/IndexView.vue'),
    meta: { title: "Online Stil Danışmanlığı Randevusu" },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: "smooth" };
  },
})

const DEFAULT_TITLE = "Oleg Cassini";
router.afterEach((to) => {
    document.title = to.meta.title + " - " + DEFAULT_TITLE || DEFAULT_TITLE;

    if (
        window.innerWidth <= 991 &&
        document.body.classList.contains("sidebar-reverse")
    ) {
        document.body.classList.remove("sidebar-reverse");
    }
});

export default router
